<template>
	<section ref="ellipsesSectionRef"
			 class="ellipses-section ">
		<div class="container-fluid">
			<FlexibleHerotext :data="data.herotext"
							  :width="'col-xs-12 col-md-10 col-md-offset-1'"
							  :override-delay="1" />
			<div class="row">
				<div class="col-xs-12 col-md-6 col-md-offset-3">
					<template v-if="data.image">
						<div ref="cardWrapperRef"
							 class="card-wrapper">
							<NuxtLink to="/about"
									  data-cursor-custom>
								<div ref="imageWrapperRef"
									 class="image-wrapper">
									<basePicture :data="data.image.data.attributes"
												 sizes="(max-width: 659px) 100vw, 50vw"
												 loading="eager" />
								</div>
								<div class="link-sub-wrapper">
									<span class="card-subtitle">About us</span>
									<span class="arrow-wrapper">
										<div class="arrow">
											<div class="line" />
											<arrowTip class="point" />
										</div>
									</span>
								</div>
							</NuxtLink>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div ref="splitTextRef"
			 class="text-wrapper">
			<h1 ref="leftTextRef">
				HOW WE
			</h1>
			<h1>&nbsp;</h1>
			<h1 ref="rightTextRef">
				DO IT
			</h1>
		</div>
		<div ref="svgWrapperRef"
			 class="ellipses-wrapper container-fluid">
			<NuxtLink v-for="(link, index) in links"
					  :key="`ellipse-link-${index}`"
					  :to="link.uri"
					  class="ellipses-link">
				<h2 ref="">
					{{ link.title }}
				</h2>
				<p class="link-subtitle">
					{{ link.subtitle }}
				</p>
				<div class="arrow">
					<div class="line" />
					<arrowTip class="point" />
				</div>
			</NuxtLink>
			<ellipsesComponent />
		</div>
	</section>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ellipsesComponent from '@/assets/images/ellipses.svg'
import arrowTip from '~/assets/images/icon-caret-right.svg'
if (process.client) gsap.registerPlugin(ScrollTrigger)

defineProps({
	data: {
		type: Object,
		required: true
	}
})

const links = [
	{
		title: 'Strategy',
		uri: '/strategy',
		subtitle: 'Setting you up for success'
	},
	{
		title: 'Design',
		uri: '/design',
		subtitle: 'Dressing you up for success'
	},
	{
		title: 'Digital',
		uri: '/digital',
		subtitle: 'Coding you up for success'
	},
]

const ellipsesSectionRef = ref(null)
const splitTextRef = ref(null)
const leftTextRef = ref(null)
const rightTextRef = ref(null)
const svgWrapperRef = ref(null)
const cardWrapperRef = ref(null)
const imageWrapperRef = ref(null)

const triggers = {}
let $q, viewportWidth

function animSplittext() {
	const container = document.querySelector('.container-fluid')
	const padding = Number(window.getComputedStyle(container, null).getPropertyValue('padding-left').replace('px', ''))
	const textInternalPadding = 8
	const toMoveLeft = leftTextRef.value.offsetLeft + textInternalPadding - padding
	const toMoveRight = viewportWidth - rightTextRef.value.offsetLeft - rightTextRef.value.offsetWidth - (textInternalPadding * 2) - padding
	const ellipseTargets = $q(['.ellipse-2', '.ellipse-3'])
	const svgHeight = $q(svgWrapperRef.value)[0].getBoundingClientRect().height

	triggers.animSplitText = gsap.timeline({
		scrollTrigger: {
			id: 'splitText',
			trigger: splitTextRef.value,
			start: 'top bottom',
			endTrigger: svgWrapperRef.value,
			end: 'top: 20%',
			scrub: 0.5,
			pinSpacing: false,
			// onLeave: () => {
			// 	gsap.to([leftTextRef.value, rightTextRef.value], { autoAlpha: 0, duration: 0.35 })
			// },
			// onEnterBack: () => {
			// 	gsap.to([leftTextRef.value, rightTextRef.value], { autoAlpha: 1, duration: 0.35 })
			// }
		}
	})
		.to(leftTextRef.value, {
			x: () => toMoveLeft * -1,
			ease: 'power2.inOut'
		})
		.to(rightTextRef.value, {
			x: () => toMoveRight,
			ease: 'power2.inOut',
		}, 0)





	triggers.animSplitEllipses = gsap.timeline({
		ease: 'power4.out',
		scrollTrigger: {
			id: 'splitEllipses',
			trigger: splitTextRef.value,
			start: 'bottom 80%',
			end: '+=' + svgHeight * 0.75,
			pinSpacing: false,
			scrub: 0.5,
		}
	})

	ellipseTargets.forEach((el, i) => {
		triggers.animSplitEllipses
			.to(ellipseTargets[i], {
				y: () => 650 * (i + 1),
				ease: 'power3.out'
			}, 0)
	})

	const ellipsesLinks = $q('.ellipses-wrapper a')
	ellipsesLinks.forEach((link, i) => {
		gsap.set(link, { autoAlpha: 0 })

		triggers[`animRevealLinks${i}`] = gsap.timeline({
			scrollTrigger: {
				trigger: link,
				start: 'top 50%',
				onEnter: () => {
					gsap.to(link, { autoAlpha: 1, duration: 0.5, ease: 'sine.inOut' })
				},
				onLeaveBack: () => {
					gsap.to(link, { autoAlpha: 0, duration: 0.5, ease: 'sine.inOut' })
				},
			}
		})
	})

	const mediaQuery = gsap.matchMedia()

	mediaQuery.add('(min-width: 768px)', () => {
		triggers.animPinText = gsap.timeline({
			scrollTrigger: {
				id: 'pinText',
				trigger: splitTextRef.value,
				start: 'top 20%',
				endTrigger: ellipsesLinks[ellipsesLinks.length - 1],
				end: 'top 20%',
				pin: splitTextRef.value,
				pinSpacing: false,
			}
		})
	})
}

function animMoveCard() {
	const imgHeight = Number($q('img')[0].getAttribute('height'))
	const imgWidth = Number($q('img')[0].getAttribute('width'))
	const heightRatio = imgHeight / imgWidth

	const containerWidth = $q(cardWrapperRef.value)[0].getBoundingClientRect().width
	// this order is important
	gsap.set($q('picture'), { height: () => containerWidth * heightRatio })
	const containerHeight = $q('picture')[0].getBoundingClientRect().height

	triggers.animMoveCard = gsap
		.timeline({
			ease: 'power4.out',
			scrollTrigger: {
				id: 'moveCard',
				trigger: cardWrapperRef.value,
				start: 'top bottom',
				end: 'bottom top',
				pinSpacing: false,
				scrub: 0.5,
			}
		})
		.to(cardWrapperRef.value, {
			y: () => (containerHeight * 0.65) * -1,
			ease: 'power1.inOut'
		}, 0)


	gsap.set($q('img'), { y: 0, height: () => containerHeight * 1.4 })
	const actualImgHeight = $q('img')[0].getBoundingClientRect().height
	const toMove = actualImgHeight - containerHeight


	triggers.animImgParallax = gsap
		.timeline({
			scrollTrigger: {
				id: 'parallaxEllipsesImg',
				trigger: cardWrapperRef.value,
				start: 'top bottom',
				end: 'bottom top',
				scrub: true,
			},
		})
		.fromTo($q('img'), {
			y: 0,
		}, {
			y: toMove * -1,
		})

	ScrollTrigger.refresh(true)
}

function initAnims() {
	$q = gsap.utils.selector(ellipsesSectionRef.value)
	viewportWidth = window.innerWidth

	animSplittext()
	animMoveCard()
}

onMounted(() => {
	useAnims(initAnims, triggers)
})
</script>

<style lang="scss" scoped>
.ellipses-section {
	@include standard-margin-bottom;

	:deep(.flexible-herotext) {
		margin-bottom: rem-calc(100);
	}

	.card-wrapper {
		position: relative;
		display: block;
		margin-bottom: rem-calc(50);

		a:hover {
			.arrow-wrapper {
				flex-grow: 1;
			}
		}

		.image-wrapper {
			position: relative;
			display: block;
			overflow: hidden;
		}

		.link-sub-wrapper {
			display: flex;
			flex-flow: row nowrap;
			gap: 1em;
			margin-top: rem-calc(16);

			.card-subtitle {
				@include textBody;
				position: relative;
			}

			.arrow-wrapper {
				position: relative;
				flex-grow: 0;
				transition: flex-grow 1.1s $easeOutQuint;

				.arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: calc(100% - 4px);
					min-width: rem-calc(31);
					will-change: width;
					height: rem-calc(20);

					.line {
						width: 100%;
						background-color: $black;
						float: left;
					}

					.point {
						color: $black;
					}
				}
			}
		}
	}

	.text-wrapper {
		// @include standard-padding-top;
		position: relative;
		text-align: center;

		h1 {
			position: relative;
			display: inline-block;
			text-transform: uppercase;
			color: $black;
		}
	}

	.ellipses-wrapper {
		color: $black;
		position: relative;

		a.ellipses-link {
			position: absolute;
			top: 19%;
			left: 50%;
			transform: translate(-50%, calc(-50% - 0.5em));
			text-align: center;
			display: block;
			z-index: 1;

			h2 {
				margin: 0;
				font-weight: 600;
			}

			.link-subtitle {
				position: relative;
				display: block;
				@include textBody;
				margin: 0 0 0.5em 0;

				@include for-devices-above(sm) {
					margin: 0 0 0.75em 0;
				}
			}

			&:nth-of-type(2) {
				top: 51%;
			}

			&:nth-of-type(3) {
				top: 83%;
			}

			.arrow {
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				transition-duration: 0.5s;

				.line {
					background-color: $black;
				}

				.point {
					color: $black;
				}
			}

			&:hover {
				.arrow {
					width: calc(100% - 7px);
				}
			}
		}
	}
}

:deep(picture) {
	position: relative;
	display: block;
	bottom: 0;
	width: auto;
	height: 60vmin;
	overflow: hidden;

	img {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		object-fit: cover;
		object-position: center center;
		max-width: unset;
	}
}

:deep(.flexible-herotext) {
	margin-bottom: 0;

	.hero-title {
		text-transform: uppercase;
		text-align: center;
		max-width: rem-calc(1470);
		margin: 0 auto;
	}
}

:deep(.ellipsessvg) {
	display: block;
	// filter: url('#gooey');

	path {
		stroke: $black;
		fill: transparent;
		transform-origin: center center;
	}
}
</style>